import http from '@/units/request'
import axios from 'axios'
let cancelList = null

/** *
 * 获取资质信息列表
 * ***/
export const getInvoiceInfoList = (data) => {
  if (cancelList !== null && data['page.current'] === 1) {
    cancelList('取消请求') // 如果上一次的请求还在继续，则取消
  }
  return http({
    url: '/is-company/invoiceInfo/v2/list',
    method: 'get',
    params: data,
    cancelToken: new axios.CancelToken(function (c) {
      cancelList = c
    })
  })
}
/** *
 * 获取资质信息详细
 * ***/
export const getInvoiceInfoInfo = (companyId) => {
  return http({
    url: `/is-company/invoiceInfo/v2/info/${companyId}`,
    method: 'get'
  })
}
