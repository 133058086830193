<template>
  <div class="companyAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar
      :title="'开票信息详情'"
      v-watermark
    ></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="info"
      v-watermark
    >
      <van-collapse
        v-model="activeNames"
        accordion
      >
        <van-collapse-item
          title="开票信息"
          name="1"
        >
          <van-cell
            center
            title="开票抬头:"
            :value="CompanyInfo.title || '--'"
          />
          <van-cell
            center
            title="纳税人识别号:"
            :value="CompanyInfo.taxIdNumber || '--'"
          />

          <van-cell
            center
            title="开户银行:"
            :value="CompanyInfo.openAccountBank || '--'"
          />
          <van-cell
            center
            title="开户账号:"
            :value="CompanyInfo.bankCardNumber || '--'"
          />
          <van-cell
            title="单位电话:"
            center
          >
            <template #default>
              <p
                :style="{ color: '#1990ff' }"
                v-if="CompanyInfo.telephone"
                @click="callOut(CompanyInfo.telephone)"
              >
                {{ CompanyInfo.telephone }}
              </p>
              <p v-else>--</p>
            </template>
          </van-cell>
          <van-cell
            center
            title="单位地址:"
          >
            <template #default>
              <div
                style="color: #1990ff"
                @click="addressHandler(CompanyInfo.address)"
                v-if="CompanyInfo.address"
                class="ellipsis"
              >
                {{ CompanyInfo.address }}
              </div>
              <div v-else>--</div>
            </template>
          </van-cell>
          <van-cell
            center
            title="更新时间:"
            :value="CompanyInfo.updateDatetime | dateFormat"
          />
          <userJump
            title="更新人:"
            :userList="CompanyInfo.updateBy"
          ></userJump>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import { getInvoiceInfoInfo } from '@/api/invoiceInfo'
import { Dialog } from 'vant'
import { mapState } from 'vuex'
import wx from 'weixin-js-sdk'
import index from '@/mixins'

export default {
  name: 'companyAddressInfo',
  mixins: [index],
  data() {
    return {

      activeNames: '1',
      CompanyInfo: {},
      loadingShow: false
      // showPosition: false,
      // positionActions: [{ name: '高德地图' }, { name: '百度地图' }]
    }
  },
  async created() {
    this.loadingShow = true
    try {
      const { data } = await getInvoiceInfoInfo(this.$route.params.id)
      if (data.data) {
        this.CompanyInfo = data.data
        wx.onMenuShareAppMessage({
          title: this.CompanyInfo.title, // 分享标题
          desc: `纳税人识别号:${this.CompanyInfo.taxIdNumber ? this.CompanyInfo.taxIdNumber : '--'}`, // 分享描述
          link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
          imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
          enableIdTrans: 1, // 是否开启id转译，不填默认为0
          success: function () {
            // 用户确认分享后执行的回调函数
          },
          error: function (res) {
            alert('暂无分享权限')
          },
          cancel: function () {
            // 用户取消分享后执行的回调函数
          }
        })
        this.loadingShow = false
      } else {
        this.loadingShow = false
        if (data?.status === 'E00030007') {
          this.$toast.fail({
            message: '暂无相应权限,请联系管理员!',
            duration: 500,
            onOpened: () => {
              this.$router.replace('/')
            }
          })
          return
        }
        this.$toast.fail({
          message: '该单位开票信息已被删除!!',
          duration: 500
        })
      }
    } catch (error) {
      this.loadingShow = false
      if (
        error?.response?.status === 401
      ) {
        return
      }
      if (error.message.includes('timeout')) {
        this.$toast.fail({
          message: '网络不好，请稍后再试!',
          duration: 500
        })
        return
      }
      if (error?.response?.data?.status === 'E00030007') {
        this.$toast.fail({
          message: '暂无相应权限,请联系管理员!',
          duration: 500,
          onOpened: () => {
            this.$router.replace('/')
          }
        })
        return
      }
      this.$toast.fail({
        message: '当前网络异常，请稍后再试!!',
        duration: 500
      })
    }
  },
  computed: {
    ...mapState('user', ['jurisdiction'])
  },
  methods: {
    // 呼叫手机
    callOut(val) {
      Dialog.confirm({
        title: '信息确认',
        message: `确认呼叫${val}?`
      })
        .then(() => {
          window.location.href = `tel:${val}`
        })
        .catch(() => {
          // on cancel
        })
    },
    // 单位跳转
    companyClick(id, type) {
      if (type === 3) {
        if (id && this.jurisdiction.includes('supplier:info')) {
          this.$router.push(`/workTable/supplierAddressInfo/${id}`)
        } else {
          this.$toast.fail({
            message: '暂无相关权限,请联系管理员!',
            duration: 500
          })
        }
      } else {
        if (id) {
          this.$router.push({
            name: 'companyAddressInfo',
            params: {
              Refresh: true,
              id: id
            }
          })
        } else {
          this.$toast.fail({
            message: '暂无相关权限,请联系管理员!',
            duration: 500
          })
        }
      }
    },
    // 地址弹框
    addressHandler(text) {
      this.addressClickTo(this.CompanyInfo.address, this.CompanyInfo.head)
      Dialog.confirm({
        title: '单位地址',
        message: text,
        confirmButtonColor: '#1989FA',
        confirmButtonText: '去定位',
        beforeClose: this.beforeClose

      })
        .then(() => {
          // on close
        })
        .catch(() => {
          // on cancel
        })
    },
    // 合同跳转
    agreementClick(id) {
      if (id) {
        this.$router.push(
          {
            name: 'agreementAddressInfo',
            params: {
              Refresh: true,
              id: id
            }
          }
        )
      } else {
        this.$toast.fail({
          message: '暂无相关权限,请联系管理员!',
          duration: 500
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.companyAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .info {
      height: calc(100% - 46px);
      overflow: auto;

      .van-collapse {
        background-color: transparent;

        .van-collapse-item {
          background-color: transparent;

          .van-collapse-item__title {
            background-color: white;
            font-weight: 700;
          }

          .van-collapse-item__wrapper {
            background-color: transparent;

            .van-collapse-item__content {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
</style>
